//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { documentTabDelete, documentTabPage } from '@/api/modular/fileStream/processingSignature'
import { STable } from '@/components'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
import moment from 'moment'
import addNote from './components/addNote.vue'
const columns = [
    {
        title: '處理箋名稱',
        dataIndex: 'name',
        ellipsis: true,
    },
    {
        title: '使用範圍',
        dataIndex: 'typeList',
        scopedSlots: { customRender: 'typeList' },
    },
    {
        title: '創建時間',
        dataIndex: 'createTime',
        scopedSlots: { customRender: 'createTime' },
    },
    {
        title: '更新時間',
        dataIndex: 'updateTime',
        scopedSlots: { customRender: 'updateTime' },
    },
    {
        title: '創建人',
        dataIndex: 'createBy',
        scopedSlots: { customRender: 'createBy' },
    },
    {
        title: '操作',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' },
    },
]
export default {
    components: {
        OpenData,
        STable,
        addNote,
    },
    data() {
        return {
            columns,
            queryParam: {},
            loadData: (parameter) => {
                return documentTabPage({ ...parameter, ...this.queryParam })
                    .then((res) => res.data)
                    .catch(() => {
                        return new Promise((resolve, reject) => {
                            resolve({
                                pageNo: 1,
                                pageSize: 10,
                                totalPage: 0,
                                totalRows: 0,
                                rows: [],
                                rainbow: [],
                            })
                        })
                    })
            },
        }
    },
    created() {
        init_wx()
    },
    methods: {
        singleDelete({ id }) {
            documentTabDelete([id]).then((res) => {
                if (res.code === 200) {
                    this.$message.success('刪除成功')
                    this.$refs.table.refresh()
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        onSearch(val) {
            this.$refs.table.refresh(true)
        },
        formatDate(text) {
            if (!text) return ''
            return moment(text).format('YYYY-MM-DD hh:mm:ss')
        },
        getTagName(text) {
            switch (text) {
                case 1:
                    return '收文登記'
                case 2:
                    return '快速發文'
                case 3:
                    return '擬稿發文'
            }
        },
    },
}
